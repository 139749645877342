module.exports = [{
      plugin: require('../../../../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-PHTM2LR","includeInDevelopment":true,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Milan Laser Hair Removal","short_name":"Milan Laser","description":"Laser Hair Removal | Milan Laser is one of the nation’s leading laser hair removal companies. We are committed to providing our clients with world-class customer service and permanent results. Our physicians and highly-trained medical staff utilize ongoing medical research to ensure that all treatments are performed safely and are tailored to give every client the best results possible. Our goal is to provide 100% customer satisfaction to every client and we will do everything we can to ensure our clients are happy with their experience as well as their results.","start_url":"/","background_color":"#F2FAFD","theme_color":"#F2FAFD","display":"standalone","icon":"/opt/build/repo/theme-2023/src/images/favicon.webp","icons":[{"src":"/opt/build/repo/theme-2023/src/images/android-chrome-192x192.webp","sizes":"192x192","type":"image/png"},{"src":"/opt/build/repo/theme-2023/src/images/android-chrome-256x256.webp","sizes":"256x256","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"d86e0a17ee6e0d2e70fbdb8db6f8086c"},
    },{
      plugin: require('../../../../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../../theme-2023/gatsby-browser.js'),
      options: {"plugins":[],"siteData":{"stateAbbrev":"NC","stores":[{"cm_pardot_file":"76 - 100","with_pro_images":true,"open":true,"stateAbbrev":"NC","clinicName":"","salesforceValue":"Hickory","description":{"line_two":" by Academy Sports, JoAnn Fabrics, and Harbor Freight, next to Dollar Tree and Platos Closet","line_one":"off of Highway 70 near Valley Hills Mall"},"phone":"828-358-0850","address":"2118 Highway 70 SE","platforms":{"google":"https://www.google.com/search?q=milan+laser+hair+removal+hickory+nc&oq=milan+laser+hair+removal+hickory+&aqs=chrome.0.0i355i512j46i175i199i512j69i57j0i390l2j69i60l3.4668j0j7&sourceid=chrome&ie=UTF-8#lrd=0x885131c3d56a1011:0x504367ca02a5cdc3,3,,,","facebook":"Hickory","yelp":"hickory","reviews":{"slide_token":"bYtDfu3pjIHjsz9jHXPvorkm4aLRIE6dXdNuVtZh6eaxPn6hYA","list_token":"WNNF1WG8Y7vWiRFsHNGvlBcHrxjkkW6XCbE6OKN8upKfBiysok"}},"geo":{"lat":"35.701765","long":"-81.301081"},"pathname":"hickory","maps":{"google":"https://goo.gl/maps/bpTDwztE2YYyKGvNA","apple":"https://maps.apple.com/?address=2118%20US-70,%20Hickory,%20NC%20%2028602,%20United%20States&auid=13245484540593077668&ll=35.701870,-81.301040&lsp=9902&q=Milan%20Laser%20Hair%20Removal&_ext=ChkKBQgEEOIBCgQIBRADCgQIBhBnCgQIChAAEiYpJhmUtUPZQUAxzc312p5TVMA5pO65EWraQUBBTVgTnelSVMBQBA%3D%3D&t=m"},"addressCity":"","place_id":"ChIJERBq1cMxUYgRw82lAspnQ1A","hero":{"reversed":false,"position":"center"},"zipCode":"28602","marketingCity":"Hickory","state":"North Carolina"}],"google_verification":"i2v0wjV85_AHBOdz8hIgzUZMHjobYu4gJtjD5yL_t4A","pathname":"hickory","state":"North Carolina","openStores":1,"gtag_id":"GTM-PHTM2LR","metroPath":"hickory","grandOpening":false,"google_analytics_id":"UA-144108258-17","bing_id":"32599022","name":"Hickory","origin":"https://milanlaserhickory.com"}},
    },{
      plugin: require('../../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
